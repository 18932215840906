import React from 'react'

function technical() {
  return (
    <section id = "technical" className="container">
      <div className="technical">
          <h4>
          VERIFIED SMART CONTRACT ADDRESS:&nbsp; 
          <a href="https://polygonscan.com/address/0x61776cae3cD4307f960C56eeA984c12BEc27dA01" target="_blank" rel="noopener noreferrer">
              0x61776cae3cD4307f960C56eeA984c12BEc27dA01
          </a>
          </h4>
      </div>
    </section>
  )
}

export default technical
