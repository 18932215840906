// Navbar.js 
export default function Navbar() {
  return (
    <nav>
        <a
            href="https://discord.gg/Sp23Sh2ea3"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img src="images/socialmedia/discord.png" alt="Discord" width="24" height="24"/>
        </a>
        <a
            href="https://instagram.com/mysticmuse.nft"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img src="images/socialmedia/instagram.png" alt="Instagram" width="24" height="24"/>
        </a>
        <a
            href="https://twitter.com/MysticMuseNFT"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img src="images/socialmedia/twitter.png" alt="Twitter" width="24" height="24"/>
        </a>
        <a
            href="https://opensea.io/collection/themysticmuse"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img src="images/socialmedia/opensea.png" alt="Opensea" width="24" height="24"/>
        </a>
    </nav>
  )
}