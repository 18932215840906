import React from 'react';
import Navbar from './navbar';

function header() {
  return (
    <header>
        <div className="container">
          
          <div className="logo">
            <a
              href="./" 
              target="_self"
              rel="noopener noreferrer"
            >  
              <img src="images/header/logo_white_text.png" class="logo1" alt="mysticlogo1" height="128"/>
              <img src="images/header/logo_white.png" class="logo2" alt="mysticlogo2" height="87"/>
            </a>
          </div>
          <div className="menu">
            <a href="/gallery">Gallery</a>
          <Navbar />
          </div>
        </div>
    </header>
  )
}

export default header
