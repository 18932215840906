import React from 'react'

function join() {
  return (
    <section id = "join" className="container">
      <div className="join-tribe">
          <img src="images/join/text_logo.png" alt="mystictextlogo" width="600"/>
          {/*<h2>The Mystic Muse NFT <br/> Now on OpenSea </h2>*/}
          <h4>An exclusive collection of beautiful Mystic Muses living on the Polygon blockchain by the artist Mystic</h4>
          
          <a href="https://opensea.io/collection/themysticmuse" target="_blank" rel="noopener noreferrer" className="hero-btn btn">
          </a>

          <a href="https://opensea.io/collection/themysticmuse" target="_blank" rel="noopener noreferrer">
            <img src="images/join/join.png" alt="jointribe" width="214"/>
          </a>  
      </div>
    </section>
  )
}

export default join
