import React from 'react';
import Navbar from '../navbar';

function galleryheader() {
  return (
    <header>
        <link rel="canonical" href="https://www.mysticmusenft.com"/>

        <div className="container">
          
          <div className="logo">
            <a
              href="./" 
              target="_self"
              rel="noopener noreferrer"
            >  
              <img src="images/header/logo_white_text.png" class="gallerylogo1" alt="mysticlogo1" height="128"/>
              <img src="images/header/logo_white.png" class="gallerylogo2" alt="mysticlogo2" height="87"/>
            </a>
          </div>
          <div className="menu">
          <a href="./" 
              target="_self"
              rel="noopener noreferrer">Home</a>
          <Navbar />
          </div>
        </div>
    </header>
  )
}

export default galleryheader
