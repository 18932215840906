function errorfooter() {
    return (
      <section id = "errorfooter" className="container">
        <footer>
            <div className="row">
            <div className="logo-row">
                <a href="https://www.mysticmusesecretsociety.com">
                <img src="images/x-icon/mmlogo.png" alt="logo" width="52" height="52"/>
                </a>
            </div>
            
            <p>© The Mystic Muse NFT</p>
  
            </div>
        </footer>
      </section>
    )
  }
  
  export default errorfooter
  