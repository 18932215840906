import React from 'react'

function gallerytop() {
  return (
    <section id = "gallerytop" className="container">
      <img src="/images/gallery-top/gallery.png" alt="gallerytext"/>

      <div className="gallery-image"></div>
    </section>
  )
}

export default gallerytop
