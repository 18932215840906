import React from 'react'

function top() {
  return (
    <section id = "top" className="container">
      <img src="images/top/new_banner.png" alt="mysticbanner"/>

      <div className="hero-image"></div>
    </section>
  )
}

export default top
