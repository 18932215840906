function Mystic({item: {name, image, opensea_url}}){
    return (
        <div className="col">
            <a href={opensea_url}>
                <img className="gal-image" src={image} alt="mysticmusenft"/>
            </a>
            <p>{name}</p>
        </div>
    )
}

export default Mystic