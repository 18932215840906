import InfiniteScroll from "react-infinite-scroller";
import { useEffect, useState } from "react";
import './gallery.css';
import Mystic from "./mystic";
import Loader from "./loader";

function GalleryComponent() {

  const[items, setItems] = useState([]);
  const[isLoading, setIsLoading] = useState(false);

  const [hasMore, sethasMore] = useState(true);

  const [page, setpage] = useState(1);

  useEffect(() => {
      const getMystics = async() => {

        const res = await fetch(
          `https://mystic-muse.herokuapp.com/mystics?_page=1&_limit=20`
        );
        const data = await res.json();
        setItems(data);
      };

      getMystics();
  }, []);

  const fetchMystics = async () => {
    const res = await fetch(
      //`https://jsonplaceholder.typicode.com/comments?_page=${page}&_limit=20`
      // For json server use url below
      `https://mystic-muse.herokuapp.com/mystics?_page=${page}&_limit=20`
      //  `http://localhost:3004/mystics?_page=${page}&_limit=20`
    );
    const data = await res.json();
    return data;
  };

  const fetchData = async () => {
    if(!isLoading) {
       setIsLoading(true);
      const mysticsFormServer = await fetchMystics();

      setItems([...items, ...mysticsFormServer]);
      if (mysticsFormServer.length === 0 || mysticsFormServer.length < 20) {
        sethasMore(false);
      }
      setpage(page + 1);
      setIsLoading(false);
  }
  };

  return (
    <InfiniteScroll
      pageStart={1} //This is important field to render the next data
      loadMore={fetchData}
      style={{height: 'auto', overflow: 'hidden', backgroundcolor:'#F3F3F3'}}
      hasMore={hasMore}
      loader={<Loader />}
      useWindow={true}
    >
      <div id="gallerycontainer" className="container">
          <div className="gallery">
            <div className="row">
              {items.map((item) => {
                return <Mystic key={item.id} item={item} />;   
              })}
            </div>
          </div>
      </div>
    </InfiniteScroll>
  );
}

export default GalleryComponent;
