import React from 'react'

//components
import Layout from "../components/layout/layout";
import Top from "../components/top";
import Join from "../components/join";
import Highlights from "../components/highlights";
/*import Roadmap from "../components/roadmap";*/
import Technical from "../components/technical";

function Home() {
  return (
    <Layout>
      <Top/>
      <Join/>
      <Highlights/>
      {/* <Roadmap/> */}
      <Technical/>
    </Layout>
  )
}

export default Home
