import React from 'react'
//components
import GalleryHeader from "../components/gallery/galleryheader";
import GalleryTop from "../components/gallery/gallerytop";
import GalleryComponent from '../components/gallery';
import Technical from "../components/technical";
import Footer from "../components/footer";


function Gallery() {
  return (
    <>
      <GalleryHeader/>

      <main>
        <GalleryTop/>

        { <GalleryComponent/> }
        <Technical/>
      </main>
      <Footer/>
    </>
  )
}

export default Gallery;