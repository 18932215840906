import React from 'react'

function highlights() {
  return (
    <section id = "highlights" className="">
      <div class="column">
        <img src="images/highlights/column_1.png" class="highlightsimage" alt="highlightsimage"/>
      </div>
      <div class="column">
        <img src="images/highlights/column_2.png" class="highlightsimage" alt="highlightsimage"/>
      </div>
      <div class="column">
        <img src="images/highlights/column_3.png" class="highlightsimage" alt="highlightsimage"/>
      </div>
    </section>
  )
}

export default highlights