import React from 'react'

//components
import Header from "../components/header";
import ErrorMessage from "../components/errormessage";
import ErrorFooter from "../components/errorfooter";

function Error() {
  return (
    <div>
        <Header />
        <ErrorMessage />
        <ErrorFooter />
    </div>
  )
}

export default Error
